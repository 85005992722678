import { DefaultLocale, FakeDefaultLocale } from '@finn/ui-utils/ssr';

export {
  getBackupCosmicObject,
  getInputTooltipText,
  getInputPlaceholder,
} from '@finn/ui-cosmic/ssr';

type CosmicObjectIdentifier = {
  slug: string;
  locale: string;
};

export const getCachedCosmicObject = async ({
  slug,
  locale,
}: CosmicObjectIdentifier) => {
  if (locale === FakeDefaultLocale) {
    locale = DefaultLocale;
  }
  let data = { objects: [{}] };
  try {
    data = await import(`../cosmic-cache/${locale}/${slug}.json`);
  } catch {
    return null;
  }

  return JSON.parse(JSON.stringify(data?.objects?.[0])) || null;
};
