export type PageParams<T = {}> = Promise<
  {
    lang?: string;
    slug?: string | string[];
  } & T
>;

// design for app router migration
export const fetchSSRLocale = async (
  params: PageParams = Promise.resolve({})
) => {
  const { lang = 'de-DE' } = await params;

  return lang;
};
