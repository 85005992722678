'use client';
import { fetchSSRLocale, PageParams } from '@finn/ui-utils/ssr';

import ErrorPage from '~/components/ErrorPage';
import { getCachedCosmicObject } from '~/utils/cosmic';

const CustomErrorPage = async ({
  params,
  error,
}: {
  params: PageParams;
  error: Error;
}) => {
  const locale = await fetchSSRLocale(params);
  const pageData = await getCachedCosmicObject({
    slug: 'static-error-page',
    locale,
  });

  return <ErrorPage pageData={pageData} statusCode={500} error={error} />;
};

export default CustomErrorPage;
